<template>
  <div>
    <hex />
    <div :class="$style.wrapper">
      <l-popup-file :class="$style.popups" gray title="Video Player" right>
        <iframe
          :class="$style.iFrame"
          width="560"
          height="315"
          src="https://www.youtube.com/embed/74Rat24PXGM"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        >
        </iframe>
      </l-popup-file>
    </div>
    <l-popupMessage
        :class="$style.popupsMessage"
      :items="popups"
      @click="nextStep"
    ></l-popupMessage>

  </div>
</template>

<script>

import { ref } from '@vue/composition-api'

import hex from '@/components/@layout/hex'
import webhook from '@/webhook'

export default {
  components: {
    hex,
  },
  setup() {
    const link = ref(null)

    const popups = ref([
      {
        text: 'Молодец! У тебя получилось! Для получения сертификата воспользуйся кнопкой ниже:',
        buttonTitle: 'ПОЛУЧИТЬ СЕРТИФИКАТ',
        event: 'nextStep',
      },
    ])


    const init = async () => {
      webhook.stopTimer()
      link.value = await webhook.reqHook()
    }

    init()

    const nextStep = () => {
      let live = 'https://xn--h1adlhdnlo2c.xn--p1ai/lessons/cyberatacks-investigation/certificate'
      if (link.value !== null) {
        live = link.value.data.link
      }
      document.location.href = live
    }


    return {
      popups,
      nextStep,
    }
  },
}
</script>

<style lang="scss" module >
  .wrapper {
    position: absolute;
    z-index: 1000;
  }
  .iFrame {
    width: 110rem;
    height: 55rem;
  }
  .popups {
    height: auto !important;
  }
  .popupsMessage {
    z-index: 1000;
  }
</style>
